import React, { useState, useEffect, useRef } from 'react'
import DocumentIngestion from '../Ingestion/DocumentIngestion'
import WebPageIngestion from '../Ingestion/WebPageIngestion';
import Documents from '../Ingestion/Documents';
import { logout, verifyUser } from '../../Redux/Actions/action.users';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Category from '../Category/Category';
import CutomerManagement from './../CustomerManagement.js/CutomerManagement';
import { getCategories } from '../../Redux/Actions/action.category';
import TalkToYourData from '../TalkToYourData/TalkToYourData';


import { auth } from './../firebaseConfig';
import { signOut } from "firebase/auth";
import SampleTTYD from './../TalkToYourData/sampleTTYD';



function Dashboard({ logout, toast, verifyUser, getCategories }) {
    const userInfo = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):''
    const [page, setPage] = useState('document-ingestion');
    const menuRef = useRef(null);
    const userAccountOptionsRef = useRef(null);
    const [isMenuOpen,setIsMenuOpen] = useState(false);
    const [userAccountOptionsVisible,setUserAccountOptionsVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        getCategories();
        window.addEventListener('resize',onResize);
        return ()=>{
            window.removeEventListener('resize',onResize);
        }
    },[]);


    const onResize = () => {
        if(window.innerWidth < 1024){
            setIsMenuOpen(false);
        }
    }

    useEffect(()=>{
        if(userInfo !=='' && userInfo.user.stsTokenManager.expirationTime < Date.now() ){
            verifyUser(userInfo.user.stsTokenManager.accessToken).then(res=>{
                // Action to perform on success
            }).catch(error=>{
                console.log('Error getting user details')
                if(error?.response?.status == 401){
                    signOut(auth).then(res=>{
                        navigate('/');
                      }).catch(error=>console.log(error))
                }
            });
        }
    },[page])

    const handleLogout = async ()=>{
        logout().then(res=>{
            if(res && res.status=='success'){
                signOut(auth).then(res=>{
                    navigate('/');
                  }).catch(error=>console.log(error))
            }
            if(res && res.status=='error'){
                signOut(auth).then(res=>{
                    navigate('/');
                  }).catch(error=>console.log(error))
            }
        })
    }

    return (
        <div className='dashboard-container' onClick={(e)=>{
            // handleOutsideClick(e);
            // changeUserOptionsVisibility(e)
            }}>
            <div className={`left-side d-flex flex-column ${!isMenuOpen?'menu-close':'menu-open'}`} ref={menuRef}>
                <div className='dashboard-logo d-flex align-items-center'>
                    <img src='./images/logo.png' className='my-3 mx-2' alt='logo' />
                    <div className={`ms-auto me-3 menu-icon burgerMenuIcon ${!isMenuOpen?'menu-close':'menu-open'}`} onClick={()=>{setIsMenuOpen(prev=>!prev)}}>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                </div>
                <div className={`dashboard-greetings`}>
                    <p className='greeting mb-1'>Welcome Back</p>
                    <p className='username'>{userInfo?.account?.name}</p>
                </div>
                <div className='dashboard-links px-2'>
                    <div className='links-container'>
                        <div role="button" className={`px-4 py-2 link ${page == 'document-ingestion' ? 'active' : ''}`} onClick={() => { setPage('document-ingestion');setIsMenuOpen(false); }}>Document Ingestion</div>
                        <div role="button" className={`px-4 py-2 link ${page == 'webpage-ingestion' ? 'active' : ''}`} onClick={() => { setPage('webpage-ingestion');setIsMenuOpen(false); }}>Webpage Ingestion</div>
                        <div role="button" className={`px-4 py-2 link ${page == 'documents' ? 'active' : ''}`} onClick={() => { setPage('documents');setIsMenuOpen(false); }}>Documents</div>
                        {/* <div role="button" className={`px-4 py-2 link ${page == 'category' ? 'active' : ''}`} onClick={() => { setPage('category');setIsMenuOpen(false); }}>Category</div> */}
                        {/* <div role="button" className={`px-4 py-2 link ${page == 'customer-management' ? 'active' : ''}`} onClick={() => { setPage('customer-management');setIsMenuOpen(false); }}>Customer Management</div> */}
                        {/* <div role="button" className={`px-4 py-2 link ${page == 'talk-to-your-data' ? 'active' : ''}`} onClick={() => { setPage('talk-to-your-data');setIsMenuOpen(false); }}>Talk To Your Data</div> */}
                        <div role="button" className={`px-4 py-2 link ${page == 'talk-to-your-data' ? 'active' : ''}`} onClick={() => { setPage('talk-to-your-data');setIsMenuOpen(false); }}>Talk To Your Data</div>
                    </div>
                </div>
                <div className='mt-auto mx-3 user-account-dropdown-option' onClick={() => { handleLogout() }}>
                    <i className="fa-solid fa-right-from-bracket"></i><p className='ms-2 mb-0'>Logout</p>
                </div>
            </div>
            <div className='right-side d-flex flex-column' id='right'>
                <div className='document-ingestion-container-wrapper mt-0 mt-md-4' id='document-ingestion-container-wrapper'>
                    {page == 'document-ingestion' && <DocumentIngestion toast={toast}/>}
                    {page == 'webpage-ingestion' && <WebPageIngestion toast={toast}/>}
                    {page == 'documents' && <Documents toast={toast}/>}
                    {page == 'category' && <Category toast={toast}/>}
                    {page == 'customer-management' && <CutomerManagement toast={toast}/>}
                    {/* {page == 'talk-to-your-data' && <TalkToYourData toast={toast}/>} */}
                    {page == 'talk-to-your-data' && <SampleTTYD toast={toast}/>}
                </div>
                <div className='dashboard-footer py-3 mt-auto'>
                    <p className='my-0'>Copyright &copy; 2024 by BLATTNER TECHNOLOGIES. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        logout,
        verifyUser,
        getCategories,
    }, dispatch);
}
  
const mapStateToProps = (state) => {
    return {
      users: state.users,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard)